//
// Badges
// --------------------------------------------------


// Base classes
.badge {
  font-size: @font-size-small;
}

// Account for counters in navs
a.list-group-item.active > .badge,
li.list-group-item.active a > .badge {
  color: @badge-color;
  background-color: @list-group-active-color;
}
.nav-pills > .active > a > .badge {
  color: @link-color;
  background-color: @badge-active-bg;
}
