//
// Button groups
// --------------------------------------------------

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
  // Space out series of button groups
  > .btn,
  > .btn-group,
  > .input-group {
    margin-left: 16px;
  }
}


// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.btn-group-xs > .btn { &:extend(.btn-xs); }
.btn-group-sm > .btn { &:extend(.btn-sm); }
.btn-group-lg > .btn { &:extend(.btn-lg); }


// Split button dropdowns
// ----------------------

// Give the line between buttons some depth
.btn-group > .btn + .dropdown-toggle {
  .box-shadow(none);
}

.btn-group {
  > .dropdown-toggle:hover {
    .box-shadow(0 1px 1px rgba(0,0,0,0.1));
  }
  > .btn-primary.dropdown-toggle:hover,
  > .btn-info.dropdown-toggle:hover,
  > .btn-warning.dropdown-toggle:hover,
  > .btn-danger.dropdown-toggle:hover,
  > .btn-success.dropdown-toggle:hover {
    .box-shadow(0 1px 1px rgba(0,0,0,0.2));
  }
  > .btn.dropdown-toggle:active,
  > .btn.dropdown-toggle.active {
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.1));
  }
  > .btn-primary.dropdown-toggle:active,
  > .btn-primary.dropdown-toggle.active,
  > .btn-warning.dropdown-toggle:active,
  > .btn-warning.dropdown-toggle.active,
  > .btn-danger.dropdown-toggle:active,
  > .btn-danger.dropdown-toggle.active,
  > .btn-success.dropdown-toggle:active,
  > .btn-success.dropdown-toggle.active,
  > .btn-info.dropdown-toggle:active,
  > .btn-info.dropdown-toggle.active {
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.3));
  }
  > .btn-sm.dropdown-toggle {
    padding: 5px 7px;
  }
  > .btn-lg.dropdown-toggle {
    padding: 9px 9px;
  }
}

.btn-group.open {

  // TODO - create a dropdown-toggle-variant mixin to clean things up

  // The clickable button for toggling the menu
  // Remove the gradient and set the same inset shadow as the :active state
  .dropdown-toggle {
    .box-shadow(inset 0 1px 6px rgba(0, 0, 0, 0.15));
  }

  // Keep the hover's background when dropdown is open
  .btn.dropdown-toggle {
    background-color: @btn-default-bg;
    background-image: none;
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.1));
  }
  .btn-primary.dropdown-toggle {
    background-color: @btn-primary-bg;
    background-image: none;
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.3));
  }
  .btn-warning.dropdown-toggle {
    background-color: @btn-warning-bg;
    background-image: none;
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.3));
  }
  .btn-danger.dropdown-toggle {
    background-color: @btn-danger-bg;
    background-image: none;
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.3));
  }
  .btn-success.dropdown-toggle {
    background-color: @btn-success-bg;
    background-image: none;
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.3));
  }
  .btn-info.dropdown-toggle {
    background-color: @btn-info-bg;
    background-image: none;
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.3));
  }
}


// Carets in other button sizes
.btn-lg .caret {
  border-width: @caret-width-large @caret-width-large 0;
}
// Upside down carets for .dropup
.dropup .btn-lg .caret {
  border-width: 0 @caret-width-large @caret-width-large;
}


// Vertical button groups
// ----------------------

.btn-group-vertical > .btn {
  &:first-child:not(:last-child) {
    border-top-right-radius: @border-radius-base;
  }
  &:last-child:not(:first-child) {
    border-bottom-left-radius: @border-radius-base;
  }
}
