//
// Pager pagination
// --------------------------------------------------

.pager {
  margin: @line-height-computed 0;
  li {
    > a,
    > span {
      padding: 11px 24px;
      overflow: visible;
      font-size: @font-size-large;
      // font-weight: normal;
      color: @pager-color;
      text-decoration: none;
      white-space: nowrap;
      cursor: default;
      background-color: @pager-bg;
      border: 1px solid #5b5b5b; //old browsers fallback (ie8 etc)
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: @pager-border-radius;
      outline: none;
      @shadow: 0 2px 1px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
      .box-shadow(@shadow);
      .reset-filter();
    }

    > a:hover,
    > a:focus {
      color: @pager-hover-color;
      background-color: @pager-hover-bg;
    }

    > a:active {
      color: @pager-active-color;
      background-color: @pager-active-bg;
    }

    .icon-prev {
      .chevron(left, 4px, @pager-bg, -5px, 1.5px);
      padding-right: 8px;
    }
    .icon-next {
      .chevron(right, 4px, @pager-bg, -5px, 1.5px);
      padding-left: 8px;
    }
  }

  .disabled {
    > a,
    > a:hover,
    > a:focus,
    > span {
      .box-shadow(none);
      color: @pager-disabled-color;
      background-color: @pager-disabled-bg;
      border-color: @pager-disabled-border;
      .reset-filter();
    }

    .icon-prev {
      .chevron(left, 4px, @pager-disabled-bg, -5px, 1.5px, @pager-disabled-color);
      padding-right: 8px;
    }
    .icon-next {
      .chevron(right, 4px, @pager-disabled-bg, -5px, 1.5px, @pager-disabled-color);
      padding-left: 8px;
    }
  }
}
