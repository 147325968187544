//
// Dropdown menus
// --------------------------------------------------


// The dropdown menu (ul)
.dropdown-menu {
  padding: 6px 0;
  margin: 1px 0 0; // override default ul
  font-size: @font-size-base;
  background-color: @dropdown-bg;
  border: 1px solid @dropdown-fallback-border; // IE8 fallback
  border: 1px solid @dropdown-border;
  border-radius: 0;
  .box-shadow(0 2px 4px @dropdown-border);

  // Dividers (basically an hr) within the dropdown
  .divider {
    .nav-divider(@dropdown-divider-bg);
  }

  // Links within the dropdown menu
  > li > a {
    padding: 3px 30px;
    position: relative;

    // icon within the menu item
    .glyphicon {
      position: absolute;
      left: 7px;
      top: 4px;
    }
  }
}

// Hover/Focus state
.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  color: @dropdown-link-hover-color;
  #gradient > .vertical(@start-color: @dropdown-link-hover-bg; @end-color: @dropdown-link-hover-bg);
  background-color: @gray-lighter;
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: @dropdown-link-active-color;
    #gradient > .vertical(@start-color: @dropdown-link-hover-bg; @end-color: @dropdown-link-hover-bg);
    background-color: @gray-lighter;
  }
}

// Dropdown section headers
.dropdown-header {
  color: @gray-light;
}

// Sub menus
// ---------------------------

// Original Bootstrap sub menus
.dropdown-submenu {
  position: relative;
}
// Default dropdowns
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: -1px;
  border-radius: 0;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

// Dropups
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  border-radius: 0;
}

// Caret to indicate there is a submenu
.dropdown-submenu > a:after {
  position: absolute;
  right: 10px;
  &:extend(.caret-right);
  margin-top: (@caret-width-base + 1);
  content: "";
}

// Left aligned submenus
.dropdown-submenu.pull-left {
  // Undo the float
  // Yes, this is awkward since .pull-left adds a float, but it sticks to our conventions elsewhere.
  float: none !important;

  // Positioning the submenu
  > .dropdown-menu {
    left: -100%;
    margin-left: 18px;
    border-radius: 0;
  }
}
