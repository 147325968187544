//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  margin: @line-height-computed 0;
  border-radius: @border-radius-base;

  > li {
    > a,
    > span {
      color: @pagination-color;
      padding: @pagination-padding-vertical @pagination-padding-horizontal;
      line-height: @line-height-base;
      background-color: @pagination-bg;
      border: 1px solid @pagination-border;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
        .border-left-radius(@border-radius-base);
      }
    }
    &:last-child {
      > a,
      > span {
        .border-right-radius(@border-radius-base);
      }
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      z-index: 2;
      color: @pagination-color;
      background-color: @pagination-hover-bg;
      border-color: @pagination-hover-border;

      .box-shadow(0 1px 1px rgba(0,0,0,0.1));
     }
  }
  > li > a:active {
    #gradient > .vertical(@start-color: #f6f6f6; @end-color: #f1f1f1);
    .reset-filter();
    background-color: #f4f4f4;
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.1));
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      color: @pagination-active-color;
      background-color: @pagination-active-bg;
      border-color: @pagination-active-border;
      .box-shadow(none);
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: @pagination-disabled-color;
      border-color: @pagination-disabled-border;

      #gradient > .vertical(@start-color: #f5f5f5; @end-color: #f1f1f1);
      .reset-filter();
      background-color: @pagination-disabled-bg;

      .box-shadow(none);
      text-shadow: none;
    }
  }
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
  .pagination-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @border-radius-large);
}

// Small
.pagination-sm {
  .pagination-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @border-radius-small);
}
