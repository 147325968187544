//
// Typography
// --------------------------------------------------


// Emphasis & misc
// -------------------------

// Contextual emphasis
.text-primary {
 .text-emphasis-variant(@brand-primary);
}
.text-warning {
  .text-emphasis-variant(@state-warning-text);
}

// Contextual backgrounds
// For now we'll leave these alongside the text classes until v4 when we can
// safely shift things around (per SemVer rules).
.bg-primary {
  // Given the contrast here, this is the only class to have its color inverted
  // automatically.
  color: #fff;
  .bg-variant(@brand-primary);
}
.bg-warning {
  .bg-variant(@state-warning-bg);
}
