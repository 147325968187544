//
// List groups
// --------------------------------------------------

// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {
  color: @gray-darker;
  background-color: @list-group-bg;
  border: 1px solid @list-group-border;

  // Round the first and last items
  &:first-child {
    .border-top-radius(@list-group-border-radius);
  }
  &:last-child {
    .border-bottom-radius(@list-group-border-radius);
  }

  .dropdown {
    display: none;
  }
  .dropdown-toggle {
    display: inline-block;
    padding: 5px 6px 5px 5px;
    color: @gray-darker;
  }
}

// Linked list items
//
// Use anchor elements instead of `li`s or `div`s to create linked list items.
// Includes an extra `.active` modifier class for showing selected items.

a.list-group-item {
  color: @list-group-link-color;

  .list-group-item-heading {
    color: @list-group-link-heading-color;
  }

  // Active class on item itself, not parent
  &.active,
  &.active:hover,
  &.active:focus {
    color: @list-group-active-color;
    font-weight: bold;
    background-color: @list-group-active-bg;
    border-color: @list-group-active-border;
    border-left: 4px solid @list-group-active-color;
    border-left-color: @list-group-active-color;

    .list-group-item-text {
      font-weight: normal;
      color: @list-group-active-text-color;
    }
  }
  &.active:hover,
  &.active:focus {
    background-color: @list-group-hover-bg;
  }
}
a.list-group-item,
li.list-group-item a {
  // Hover state
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: @list-group-hover-bg;
  }
}
li.list-group-item {
  padding: 0;
  margin-bottom: 0;
  border: 0 none;
  > a {
    color: @gray-darker;
    display: block;
    padding: @padding-base-vertical 17px;
    margin: 0 0 0 14px;
  }

  &.active {
    font-weight: bold;
    background-color: @list-group-active-bg;
    border-left: 4px solid #DD4B39;
    > a {
      margin-left: 10px;
      color: @list-group-active-color;
    }
  }
}

// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

.list-group-item-variant(success; @state-success-bg; @state-success-text);
.list-group-item-variant(info; @state-info-bg; @state-info-text);
.list-group-item-variant(warning; @state-warning-bg; @state-warning-text);
.list-group-item-variant(danger; @state-danger-bg; @state-danger-text);

// List group item wrapper
// -------------------------

.list-group-item-wrapper {
  margin-left: 14px;
  &:hover > .dropdown {
    display: block;
  }
  > a {
    display: block;
    padding: 5px 17px;
    margin: 0;
    color: @gray-darker;
    // &:hover {
    //   background-color: @gray-lighter !important;
    // }
  }
  > .dropdown {
    &:hover + a {
      background-color: @gray-lighter;
    }
    &.open {
      display: block;
      + a {
        background-color: @gray-lighter;
      }
    }
    > .dropdown-menu {
      margin-top: 0;
    }
  }
}

// List group headers
// -------------------------

.list-group-header {
  display: block;
  padding: 10px 30px 10px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: @line-height-base;
  color: @gray-light;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  text-transform: uppercase;

  li& {
    padding: 3px 15px;
  }

  .list-group & {
    margin-top: 9px;
  }
}

// Collapsible menu
.list-group-item-menu {
  // No need to set list-style: none; since .list-group-item is block level
  padding: 0;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  .box-shadow(none);

  .list-group-item-wrapper > a {
    padding-left: 30px;
  }
  .list-group-item-menu .list-group-item-wrapper > a {
    padding-left: 44px;
  }
  > .list-group-item {
    .collapse-caret {
      margin-left: 28px;
    }
  }
}

// Caret
.collapse-caret {
  position: absolute;
  z-index: 1;
  display: inline-block;
  width: 17px;
  height: 28px;
  margin-left: 14px;
  &:before {
    content: '';
    position: absolute;
    top: 12px;
    left: 5px;
    margin-left: 0;
    &:extend(.caret);
    border-bottom: 0 dotted;
  }
  &:hover {
    background-color: @gray-lighter;
  }

  &.collapsed:before {
    top: 10px;
    left: 6px;
    &:extend(.caret-right);
  }
}

// Dividers (basically an hr) within the list group
.list-group .divider {
  .nav-divider();
    margin-right: 15px;
    margin-left: 15px;
}




// Sidebar list group
// -------------------------

// .nav-list-sidebar {
//   .transition(color 0.218s ease 0s);
//   a {
//     color: @gray-darker;
//   }
//   .btn {
//     color: @btn-default-color;
//   }
//   &:hover a,
//   &:hover .active > a {
//     color: @link-color;
//   }
//   &:hover,
//   &:focus,
//   &:active,
//   &.active & {
//     .btn,
//     > .btn {
//       color: @btn-default-color;
//     }
//   }
//   li {
//     margin-top: 3px;
//   }
//   .nav-header {
//     margin-bottom: 5px;
//     color: #808080;
//     // border-bottom: 1px solid #E9E9E9;
//     position: relative;
//     overflow: hidden;
//     &:after {
//       border-bottom: 1px solid #E9E9E9;
//       content: '';
//       margin-left: 10px;
//       margin-top: 10px;
//       position: absolute;
//       width: 100%;
//     }
//    }
// }
// .nav-list-sidebar > .active > a,
// .nav-list-sidebar > .active > a:hover,
// .nav-list-sidebar > .active > a:focus {
//   padding-left: 15px;
//   font-weight: bold;
//   color: @gray-darker;
//   border-left: 0 none;
// }
// .nav-list-sidebar > .active > a:hover,
// .nav-list-sidebar > li > a:hover,
// .nav-list-sidebar > li > a:active,
// .nav-list-sidebar > li > a:focus {
//   color: @link-color;
//   background-color: transparent;
//   text-decoration: underline;
// }
